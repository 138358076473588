import React, { useState, useEffect } from 'react';
import { bool } from 'yup';

// import axios from '../lib/axios';
import { sendData } from '../lib/auth';
import { spinner } from '../lib/fetching-util';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  id: '',
  isAdmin: bool,
  name: '',
  niy: '',
  status: null,
  deviceToken: '',
  login: ({ token, user }) => {},
  logout: () => {},
});

const remainingTime = expTime => {
  const currTime = new Date().getTime();
  const remainingDuration = expTime - currTime;

  return remainingDuration;
};

const getUserData = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem('simakUser'));
  } catch (error) {
    user = null;
  }
  const expTime = localStorage.getItem('expTime');

  const remainingDuration = remainingTime(expTime);

  if (remainingDuration <= 10800000) return null;

  return {
    user,
    duration: remainingDuration,
  };
};

export const AuthContextProvider = props => {
  const userData = getUserData();

  // const [token, setToken] = useState(null);
  const [user, setUser] = useState(userData?.user || null);
  const [isLoading, setIsLoading] = useState(true);

  const userIsLoggedIn = !!user;
  // const userIsLoggedIn = !!token;
  const numIsAdmin = user?.is_admin;
  const userIsAdmin = !!(numIsAdmin && +numIsAdmin === 502);
  const id = user?.id;
  const name = user?.name;
  const niy = user?.niy;
  const status = +user?.status;
  const deviceToken = user?.device_token;
  // axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  const logoutHandler = () => {
    sendData('logout', null, 'POST')
      // .then(res => {
      // setToken(null);
      // setUser(null);
      // setUser({});
      // localStorage.removeItem('accessDeiToken');
      // localStorage.removeItem('deiUser');
      // delete axios.defaults.headers.common.Authorization;
      // })
      .finally(() => {
        if (logoutTimer) clearTimeout(logoutTimer);
        setUser(null);
        localStorage.removeItem('simakUser');
        localStorage.removeItem('expTime');
        localStorage.removeItem('accessDeiToken');
      });
    // .catch(err => {
    //   // setToken(null);
    //   setUser({});
    //   // localStorage.removeItem('accessDeiToken');
    //   localStorage.removeItem('deiUser');
    //   delete axios.defaults.headers.common.Authorization;
    // });
  };

  const loginHandler = ({ token, user }) => {
    const expTime = 1728000000;
    const remainingDuration = remainingTime(new Date().getTime() + expTime);

    localStorage.setItem('expTime', new Date().getTime() + expTime);
    // localStorage.setItem('accessDeiToken', token);
    localStorage.setItem('simakUser', JSON.stringify(user));
    // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // setToken(token);
    setUser(user);

    logoutTimer = setTimeout(logoutHandler, remainingDuration);
  };

  useEffect(() => {
    // if (!token) {
    //   setToken(localStorage.getItem('accessDeiToken'));
    //   setUser(JSON.parse(localStorage.getItem('deiUser')));
    // }
    if (userData) {
      logoutTimer = setTimeout(logoutHandler, userData.duration);
    }
    setIsLoading(false);
  }, [userData]);

  const contextValue = {
    // token,
    isLoggedIn: userIsLoggedIn,
    isAdmin: userIsAdmin,
    id,
    name,
    niy,
    status,
    deviceToken,
    ...user,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {isLoading && spinner}
      {!isLoading && props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
