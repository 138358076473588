import { Suspense, lazy } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import ProtectRoute from './components/protect-route';
import ProtectAuth from './components/protect-route/protect-auth';
import ProtectAdm from './components/protect-route/protect-adm';
const DashboardPage = lazy(() => import('./pages/user/dashboard'));
const InfoUjian = lazy(() => import('./pages/user/ujian'));
const NilaiPage = lazy(() => import('./pages/user/nilai'));
const ProfilePage = lazy(() => import('./pages/user/setting/profile'));
const DetailNilaiPage = lazy(() => import('./pages/user/nilai/detail/detail'));
const ChangePasswordPage = lazy(() =>
  import('./pages/user/setting/change-password')
);
const SlipGajiPage = lazy(() => import('./pages/user/pegawai/slipGaji'));
const HasilUjian = lazy(() => import('./pages/admin/hasil-ujian'));
const KategoriPage = lazy(() => import('./pages/admin/soal/kategori'));
const KodeUjianPage = lazy(() => import('./pages/admin/soal/kode-ujian'));
const TambahSoalPage = lazy(() => import('./pages/admin/soal/tambah'));
const BankSoalPage = lazy(() => import('./pages/admin/soal/bank-soal'));
const EditSoalPage = lazy(() => import('./pages/admin/soal/edit/edit'));
const AktifasiPage = lazy(() => import('./pages/admin/soal/aktifasi'));
const List = lazy(() => import('./pages/admin/user/list'));
const UserManagementPage = lazy(() => import('./pages/admin/user/group'));
const ImportGajiPage = lazy(() =>
  import('./pages/admin/kepegawaian/import-gaji')
);
const PkwtPage = lazy(() => import('./pages/admin/kepegawaian/pkwt'));
const LayoutTw = lazy(() => import('./components/user/layout/layoutTw'));
const AuthPage = lazy(() => import('./pages/auth/AuthPage'));

// import ImportPkwtPage from './pages/admin/kepegawaian/import-pkwt';
// import AntTablePage from './pages/admin/kepegawaian/ant-table';
// import DeiSurvey from './pages/user/survey';
// import SurveyResultPage from './pages/admin/kepegawaian/survey-result';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="auth" />} />

      <Route element={<ProtectAuth />}>
        <Route
          path="auth"
          element={
            <Suspense>
              <AuthPage />
            </Suspense>
          }
        />
      </Route>

      <Route element={<ProtectRoute />}>
        <Route element={<LayoutTw />}>
          <Route
            path="user/dashboard"
            element={
              <Suspense>
                <DashboardPage />
              </Suspense>
            }
          />
          <Route
            path="user/ujian"
            element={
              <Suspense>
                <InfoUjian />
              </Suspense>
            }
          />
          {/* <Route path="user/survey" element={<DeiSurvey />} /> */}
          <Route
            path="user/nilai"
            element={
              <Suspense>
                <NilaiPage />
              </Suspense>
            }
          />
          <Route
            path="user/setting/profile"
            element={
              <Suspense>
                <ProfilePage />
              </Suspense>
            }
          />
          <Route
            path="user/nilai/detail/:userId/:niy"
            element={
              <Suspense>
                <DetailNilaiPage />
              </Suspense>
            }
          />
          <Route
            path="user/nilai/detail/:schId"
            element={
              <Suspense>
                <DetailNilaiPage />
              </Suspense>
            }
          />
          <Route
            path="user/setting/change-password"
            element={
              <Suspense>
                <ChangePasswordPage />
              </Suspense>
            }
          />
          <Route
            path="user/slip-gaji"
            element={
              <Suspense>
                <SlipGajiPage />
              </Suspense>
            }
          />

          <Route element={<ProtectAdm />}>
            <Route
              path="admin/hasil-ujian"
              element={
                <Suspense>
                  <HasilUjian />
                </Suspense>
              }
            />
            <Route
              path="admin/soal/kategori"
              element={
                <Suspense>
                  <KategoriPage />
                </Suspense>
              }
            />
            <Route
              path="admin/soal/kode-ujian"
              element={
                <Suspense>
                  <KodeUjianPage />
                </Suspense>
              }
            />
            <Route
              path="admin/soal/tambah"
              element={
                <Suspense>
                  <TambahSoalPage />
                </Suspense>
              }
            />
            <Route
              path="admin/soal/bank-soal"
              element={
                <Suspense>
                  <BankSoalPage />
                </Suspense>
              }
            />
            <Route
              path="admin/soal/edit/:soalId"
              element={
                <Suspense>
                  <EditSoalPage />
                </Suspense>
              }
            />
            <Route
              path="admin/soal/aktifasi"
              element={
                <Suspense>
                  <AktifasiPage />
                </Suspense>
              }
            />
            <Route
              path="admin/user/list"
              element={
                <Suspense>
                  <List />
                </Suspense>
              }
            />
            <Route
              path="admin/user/group"
              element={
                <Suspense>
                  <UserManagementPage />
                </Suspense>
              }
            />
            <Route
              path="admin/employee/import-gaji"
              element={
                <Suspense>
                  <ImportGajiPage />
                </Suspense>
              }
            />
            <Route
              path="admin/employee/pkwt"
              element={
                <Suspense>
                  <PkwtPage />
                </Suspense>
              }
            />
            {/* <Route path="admin/employee/anttable" element={<AntTablePage />} />
  <Route
    path="admin/employee/survey-result"
    element={<SurveyResultPage />}
  /> */}
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
